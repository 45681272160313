<template>
	<div class="EBG">
		<Header :type="type" />
		<div class="mainE"
			:style="{'marginTop':method.ratioEquality(1854,90,width-100)+30+'px','minHeight':height-method.ratioEquality(1854,90,width-100)-30+'px'}">
			<fillinNav :stage="stage" :type="type" :versions="versions" :currentColumn='4' />

			<div class="fillinWidth">
				<div class="fillinTitleE">
					<p>
						<img src="../../../assets/img/dataValutaion/dataTitle.png" />
						<span>估值报告</span>
					</p>
				</div>

				<div class="fillinMain">
					<img class="leftTop" src="../../../assets/img/dataValutaion/leftTop.png" />
					<img class="leftBottom" src="../../../assets/img/dataValutaion/leftBottom.png" />
					<img class="rightTop" src="../../../assets/img/dataValutaion/rightTop.png" />
					<img class="rightBottom" src="../../../assets/img/dataValutaion/rightBottom.png" />
					<div class="substance">
						<Payment :stage="stage" :type="type" :pathAddress="pathAddress" />
					</div>
				</div>
			</div>
		</div>


	</div>
</template>

<script>
	import Header from '@/common/header/evaluate_header.vue'
	import fillinNav from '../nav/fillDataNav.vue'
	import Payment from '@/components/prepayment/index'

	import {
		mapState,
		mapMutations
	} from 'vuex'

	export default {
		components: {
			Header,
			fillinNav,
			Payment
		},
		data() {
			return {
				width: window.innerWidth,
				height: window.innerHeight,
				stage: '',
				type: '15',
				versions: '',
				pathAddress: {
					basicInformation: '/dataValuationInfoFast'
				}



			}
		},
		mounted() {
			this.pageModify('false');
		},
		methods: {
			...mapMutations(['pageModify']),




		}



	}
</script>

<style scoped lang="less">
	@import "~@/assets/css/dataValuationNew.less";

	.fillinMain {
		height: calc(100% - 62px);
	}

	.substance {
		height: 93%;
	}
</style>