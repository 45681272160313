<template>
	<div class="EBG">
		<Header :type="type" />
		<div class="mainE" :style="{'height':'calc(100% - '+method.ratioEquality(1854,109,width-40)+'px)'}">
			<FillNav />

			<div class="fillinWidth">
				<p class="fillinTitleE">
					<span>测评报告</span>
				</p>

				<div class="fillinMain">
					<img class="leftTop" src="../../../assets/img/dataEvaluation/filled/leftTop.png" />
					<img class="leftBottom" src="../../../assets/img/dataEvaluation/filled/leftBottom.png" />
					<img class="rightTop" src="../../../assets/img/dataEvaluation/filled/rightTop.png" />
					<img class="rightBottom" src="../../../assets/img/dataEvaluation/filled/rightBottom.png" />
					<img class="leftCenter" src="../../../assets/img/dataEvaluation/filled/leftCenter.png" />
					<div class="substance">
						<Payment :stage="stage" :type="type" :pathAddress="pathAddress" />
					</div>
				</div>

			</div>
		</div>



	</div>
</template>

<script>
	import Header from '@/common/header/evaluate_header.vue'
	import FillNav from '../sidebar/fillNav.vue'
	import Payment from '@/components/prepayment/index'

	import {
		mapState,
		mapMutations
	} from 'vuex'


	export default {
		components: {
			Header,
			FillNav,
			Payment

		},
		data() {
			return {
				width: window.innerWidth,
				stage: '',
				type: 14,
				versions: '',
				pathAddress: {
					basicInformation: '/basicInformationEvaluation'
				}

			}
		},
		mounted() {
			this.pageModify('false');

		},
		methods: {
			...mapMutations(['pageModify']),

		}

	}
</script>

<style scoped src="../../../assets/css/evaluateCommon.css"></style>
<style scoped lang="less">
	.saveInfo {
		margin-top: 20px;
	}

	.fillinContent {
		display: flex;
		align-items: center;
		box-sizing: content-box;

		>div {
			display: flex;
			justify-content: space-between;

			>.guzhiReport {
				width: 42%;
				object-fit: contain;
			}

			.reportInfo {
				width: 44%;
				margin-right: 35px;

				>p {
					font-size: 20px;
					font-weight: bold;
					color: #FFFFFF;
					margin-bottom: 19px;
				}

				ul {
					li {
						position: relative;
						margin-bottom: 12px;

						span:first-child {
							width: 9px;
							height: 9px;
							background: #29FFFC;
							transform: rotate(45deg);
							position: absolute;
							top: 8px;
						}

						span:last-child {
							width: calc(100% - 9px);
							font-size: 12px;
							color: #FFFFFF;
							margin-left: 23px;

							i {
								font-weight: bold;
							}

						}
					}
				}

				.complete_info {
					font-size: 14px;
					color: red;
					margin-top: 31px;
				}

				.price {
					margin-top: 14px;

					span {
						font-size: 18px;
						font-weight: bold;
						color: #29FFFC;
					}

					del,
					.fixPrice {
						font-size: 14px;
						font-weight: 400;
						color: #FFFFFF;
						margin-left: 25px;
					}

					.fixPrice {
						text-decoration: line-through;
					}
				}

			}
		}
	}

	.hint {
		width: 85%;
		padding: 16px 0 0;
		margin: 0 auto;
	}
</style>