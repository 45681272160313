<template>
	<div>
		<div class="tools">
			<button @click="pdf()">导出PDF</button>
		</div>
		<div id="pageContent">
			<!-- 封面 -->
			<div class="homePage page">
				<div class="content" id="homePage">
					<div class="logoBox">
						<!-- <h1 id="logo"></h1> -->
						<img v-if="!channel" id="logo" src="../../assets/img/report/flowData.png" alt="线上估值-心流" />
						<div class="stock-logo" v-else>
							<img src="../../assets/img/report/flowData.png" alt="线上估值-心流" />
							<span class="logoXian"></span>
							<img :src="pageInfo.firstPageImage" alt="股交所logo-心流" />
						</div>
					</div>
					<p class="companyName" v-text="pageInfo.baseInfo.companyName"></p>
					<div class="homePageLine"></div>
					<div class="reportType">数据估值报告</div>
					<h4>报告编号：{{pageInfo.reportNumber}}</h4>
					<div class="page-one-footer">
						<div class="date" v-text="pageInfo.createTime ? replaceAll(pageInfo.createTime) : ''"></div>
						<p>
							<span>北京心流数据科技有限公司保留对此报告的最终解释权</span>
						</p>
					</div>
				</div>
			</div>
			<!-- 估值结果 -->
			<div class="page">
				<div class="content page-content" id="page-one">
					<div class="valueResultTitle">
						<img src="../../assets/img/report/icon4.png" class="icon_" alt="心流">
						<span>估值结果</span>
					</div>
					<p class="stamp">
						依据《中共中央、国务院关于构建数据基础制度更好发挥数据要素作用的意见》、《企业数据资源相关会计处理暂行规定》 （财会〔2023〕11号）
						、《企业会计准则第6号—无形资产》和《中小微企业估值规范》等政策规章，基于数据资产估值模型和估值指标体系，贵公司数据资产在估值基准日的市场价值为（人民币）：
					</p>
					<div class="detail_result ">
						<div class="numberBox">
							<div v-for="(item, index) in valueResultNumber" :key="index + 1" class="locationUnit">
								<p class="unit" v-if="item.val==12" style="margin-right: 12px;">
									{{method.technologyNum(pageInfo.minValue).unit}}
								</p>
								<img :src="require('../../assets/img/report/'+item.val+'.png')"
									:style="{marginTop:item.top,width:(item.val=='11'?'5px':item.val=='12'?'20px':'50px')}"
									alt="估值结果数字-心流">
							</div>
							<p class="unit">{{method.technologyNum(pageInfo.maxValue).unit}}</p>
						</div>
					</div>
					<img src="../../assets/img/report/earth.png" />
				</div>
			</div>
			<!--目录页-->
			<div class="page">
				<div class="content page-catalogue" id="page-catalogue">
					<h2>目录</h2>
					<div class="generalize">
						<div>
							<p>1</p>
							<div></div>
							<p>基本信息</p>
							<span class="dottedLine"></span>
							<span class="pageNumber pageNumActive">01</span>
						</div>
						<div>
							<p>2</p>
							<div></div>
							<p>软性指标</p>
							<span style="width: 734px;" class="dottedLine titleActive"></span>
							<span class="pageNumber pageNumActive">02</span>
						</div>
						<ul>
							<li>
								<p>基础指标</p>
								<span class="dottedLine"></span>
								<span class="pageNumber">03</span>
							</li>
							<li>
								<p>风险指标</p>
								<span class="dottedLine"></span>
								<span class="pageNumber">04</span>
							</li>
							<li>
								<p>质量指标</p>
								<span class="dottedLine"></span>
								<span class="pageNumber">05</span>
							</li>
							<li>
								<p>经济指标</p>
								<span class="dottedLine"></span>
								<span class="pageNumber">06</span>
							</li>
							<li>
								<p>流通指标</p>
								<span class="dottedLine"></span>
								<span class="pageNumber">06</span>
							</li>
							<li>
								<p>成本指标</p>
								<span class="dottedLine"></span>
								<span class="pageNumber">07</span>
							</li>
						</ul>
						<div>
							<p>3</p>
							<div></div>
							<p>MLM估值</p>
							<span class="dottedLine" style="width: 721px;"></span>
							<span class="pageNumber pageNumActive">08</span>
						</div>
						<div>
							<p>4</p>
							<div></div>
							<p>RSM估值</p>
							<span class="dottedLine" style="width: 728px;"></span>
							<span class="pageNumber pageNumActive">09</span>
						</div>
						<div>
							<p>5</p>
							<div></div>
							<p>收入预测</p>
							<span class="dottedLine" style="width: 735px;"></span>
							<span class="pageNumber pageNumActive">10</span>
						</div>
						<div>
							<p>6</p>
							<div></div>
							<p>账面净值</p>
							<span class="dottedLine" style="width: 735px;"></span>
							<span class="pageNumber pageNumActive">10</span>
						</div>
						<div>
							<p>7</p>
							<div></div>
							<p>估值结果</p>
							<span style="width: 735px;" class="dottedLine"></span>
							<span class="pageNumber pageNumActive">{{pagesNumber.length+11}}</span>
						</div>

						<div v-if="channel">
							<p>8</p>
							<div></div>
							<p>股交所简介</p>
							<span style="width: 711px;" class="dottedLine"></span>
							<span class="pageNumber pageNumActive">{{pagesNumber.length+12}}</span>
						</div>

						<div>
							<p>{{channel?9:8}}</p>
							<div></div>
							<p>免责声明</p>
							<span style="width: 735px;" class="dottedLine"></span>
							<span class="pageNumber pageNumActive">{{channel?pagesNumber.length+13:pagesNumber.length+12}}</span>
						</div>
					</div>
				</div>
			</div>
			<!-- 基本信息 -->
			<div class="page">
				<div class="content page-content" id="page-two">
					<div class="baseInfo">
						<div class="valueResultTitle">
							<img src="../../assets/img/report/icon1.png" class="icon_" alt="心流">
							<span>基本信息</span>
						</div>
						<div class="baseInfoCont">
							<span id="companyName" v-text="pageInfo.baseInfo.companyName"></span>
							<ul>
								<li>
									<span style="vertical-align: top;">所属行业：</span>
									<span id="industry"
										v-text="pageInfo.baseInfo.industryName + '-' + pageInfo.baseInfo.industryChildName"></span>
								</li>
								<li>
									<span>详细地址：</span>
									<span id="companyLocation"
										v-text="pageInfo.baseInfo.address ? pageInfo.baseInfo.address : '暂无数据'"></span>
								</li>
								<li>
									<span>统一社会信用代码：</span>
									<span id="socialCreditCode" v-text="pageInfo.baseInfo.creditCode"></span>
								</li>
								<li>
									<span>法定代表人：</span>
									<span id="corporateRepresentative"
										v-text="pageInfo.baseInfo.legalPeople ? pageInfo.baseInfo.legalPeople : '暂无数据'"></span>
								</li>
								<li>
									<span>成立日期：</span>
									<span id="establishmentDate" v-text="dateFormate(pageInfo.baseInfo.establishDate)"></span>
								</li>
								<li>
									<span>注册资本：</span>
									<span id="establishmentDate"
										v-text="pageInfo.baseInfo.regCapital ? method.formateNum(pageInfo.baseInfo.regCapital,2)+'万元' : '暂无数据'"></span>
								</li>
								<li>
									<span>数据类型：</span>
									<span id="dataResourceType" v-text="dataType ? dataType : '暂无数据'"></span>
								</li>
								<li>
									<span>数据量级：</span>
									<span v-text="pageInfo.dataLevel?pageInfo.dataLevel.optionName.substring(2):'暂无数据'"></span>
								</li>
								<li>
									<span>数据资源所处周期：</span>
									<span v-text="pageInfo.dataPeriod?pageInfo.dataPeriod.optionName.substring(2):'暂无数据'"></span>
								</li>
								<li>
									<span>权属情况：</span>
									<span v-text="pageInfo.dataOwnership?pageInfo.dataOwnership.optionName.substring(2):'暂无数据'"></span>
								</li>
							</ul>
						</div>
						<div class="mainBusiness">
							<div class="valueResultTitle">
								<img src="../../assets/img/report/icon2.png" class="icon_" alt="心流">
								<span>数据资源介绍</span>
							</div>
							<div class="mainBusinessCont">
								<p class="bussinessDesc"
									v-text="!pageInfo.baseInfo.dataDesc ?'暂无数据':pageInfo.baseInfo.dataDesc.length>207? pageInfo.baseInfo.dataDesc.substring(0, 207)+'...' :pageInfo.baseInfo.dataDesc ">
								</p>
							</div>
						</div>
						<div class="equityStructure">
							<div class="valueResultTitle">
								<img src="../../assets/img/report/icon3.png" class="icon_" alt="心流">
								<span>股权结构</span>
							</div>
							<div v-if="pageInfo.baseInfo.holderRation">
								<img src="../../assets/img/report/chart.png" class="piePedestal" alt="饼图-心流">
								<Draw3DPieCanvas :id="'stockStructurePie'" :chartData="pageInfo.baseInfo.holderRation"
									v-if="initStatus" />
							</div>
							<div v-else style="padding: 20px 0 0 74px;font-size: 20px;color:#000;">暂无数据</div>

						</div>
					</div>
					<div class="pagination">01</div>
				</div>
			</div>
			<!-- 软性指标 -->
			<div class="page">
				<div class="content page-content" id="page-three">
					<div class="valueResultTitle">
						<img src="../../assets/img/report/icon5.png" class="icon_" alt="心流">
						<span>软性指标</span>
					</div>
					<p class="scoreDescription">
						软性指标是评估数据价值的重要标准，包括数据的基础指标、风险指标、经济指标、质量指标、流通指标和成本指标等维度全面分析和评估，帮助企业更准确地识别和挖掘数据的潜在价值，为业务决策和创新提供有力的数据支持。
					</p>
					<div class="softIndexScore">
						<img src="../../assets/img/report/softIndicatorScore.png" />
						<div>
							<p>
								<span>{{Number(pageInfo.questionValue).toFixed(2)}}分</span>
								<span>综合得分</span>
							</p>
						</div>
					</div>
					<div class="pagination">02</div>
				</div>
			</div>
			<div class="page">
				<div class="content page-content" id="page-four">
					<div class="valueResultTitle">
						<img src="../../assets/img/report/icon6.png" class="icon_" alt="心流">
						<span>{{question[0].name}} &nbsp;{{question[0].value}}分</span>
					</div>
					<div class="indicator" v-if="pageInfo.industry">
						<img src="../../assets/img/report/basicIndex.png" />
						<ul class="basicIndexScore">
							<li style="left: 17px;">
								<p>{{pageInfo.industry[0][0].value}}分</p>
								<p>{{pageInfo.industry[0][0].name}}</p>
							</li>
							<li style="right: 17px;">
								<p>{{pageInfo.industry[0][1].value}}分</p>
								<p>{{pageInfo.industry[0][1].name}}</p>
							</li>
						</ul>
						<div>
							<img src="../../assets/img/report/basicIndexIcon.svg" />
							<p>{{question[0].name}}</p>
						</div>
					</div>
					<div class="guidelines">
						<p v-for="(item,index) in pageInfo.industry[0]">{{item.description}}</p>
					</div>
					<div class="pagination">03</div>
				</div>
			</div>
			<div class="page">
				<div class="content page-content" id="page-five">
					<div class="valueResultTitle">
						<img src="../../assets/img/report/icon7.png" class="icon_" alt="心流">
						<span>{{question[1].name}} &nbsp;{{question[1].value}}分</span>
					</div>
					<div class="indicator" v-if="pageInfo.industry" style="margin-top: 106px;">
						<img src="../../assets/img/report/riskIndicator.png" />
						<ul class="basicIndexScore riskIndicatorScore">
							<li style="left: 17px;">
								<p>{{pageInfo.industry[1][0].value}}分</p>
								<p>{{pageInfo.industry[1][0].name}}</p>
							</li>
							<li style="right: 17px;">
								<p>{{pageInfo.industry[1][1].value}}分</p>
								<p>{{pageInfo.industry[1][1].name}}</p>
							</li>
						</ul>
						<div>
							<img src="../../assets/img/report/riskIndicatorIcon.png" />
							<p>{{question[1].name}}</p>
						</div>
					</div>
					<div class="guidelines">
						<p v-for="(item,index) in pageInfo.industry[1]">{{item.description}}</p>
					</div>
					<div class="pagination">04</div>
				</div>
			</div>
			<div class="page">
				<div class="content page-content" id="page-six">
					<div class="valueResultTitle">
						<img src="../../assets/img/report/icon8.png" class="icon_" alt="心流">
						<span>{{question[2].name}} &nbsp;{{question[2].value}}分</span>
					</div>
					<div class="indicator" v-if="pageInfo.industry" style="margin-top: 129px;">
						<img src="../../assets/img/report/qualityIndex.png" />
						<ul class="qualityIndexScore">
							<li>
								<div>
									<div>
										<p>{{pageInfo.industry[2][0].name}}</p>
										<p>{{pageInfo.industry[2][0].value}}分</p>
									</div>
								</div>
								<img src="../../assets/img/report/qualityIndexScore1.png" />
							</li>
							<li style="top: 258px;left: -60px;">
								<div>
									<div>
										<p>{{pageInfo.industry[2][1].name}}</p>
										<p>{{pageInfo.industry[2][2].value}}分</p>
									</div>
								</div>
								<img src="../../assets/img/report/qualityIndexScore2.png" />
							</li>
							<li style="top: 394px;left: 54%;">
								<div>
									<div>
										<p>{{pageInfo.industry[2][2].name}}</p>
										<p>{{pageInfo.industry[2][2].value}}分</p>
									</div>
								</div>
								<img src="../../assets/img/report/qualityIndexScore3.png" />
							</li>
							<li style="top: 258px;right: -64%;">
								<div>
									<div>
										<p>{{pageInfo.industry[2][3].name}}</p>
										<p>{{pageInfo.industry[2][3].value}}分</p>
									</div>
								</div>
								<img src="../../assets/img/report/qualityIndexScore4.png" />
							</li>
							<li style="left: 108%;">
								<div>
									<div>
										<p>{{pageInfo.industry[2][4].name}}</p>
										<p>{{pageInfo.industry[2][4].value}}分</p>
									</div>
								</div>
								<img src="../../assets/img/report/qualityIndexScore5.png" />
							</li>
						</ul>
						<div>
							<p>{{question[2].name}}</p>
						</div>
					</div>
					<div class="guidelines" style="margin-top: 180px;">
						<p v-for="(item,index) in pageInfo.industry[2]">{{item.description}}</p>
					</div>
					<div class="pagination">05</div>
				</div>
			</div>
			<div class="page">
				<div class="content page-content" id="page-seven">
					<div class="valueResultTitle">
						<img src="../../assets/img/report/icon9.png" class="icon_" alt="心流">
						<span>{{question[3].name}} &nbsp;{{question[3].value}}分</span>
					</div>
					<div class="indicator" v-if="pageInfo.industry" style="margin-top: 100px;margin-bottom: 0;">
						<img src="../../assets/img/report/economicIndicators.png">
						<ul class="economicScore">
							<li style="left: -70px;">
								<span>{{pageInfo.industry[3][0].name}}</span>
								<span>{{pageInfo.industry[3][0].value}}分</span>
							</li>
							<li style="right: -70px;">
								<span>{{pageInfo.industry[3][1].name}}</span>
								<span>{{pageInfo.industry[3][1].value}}分</span>
							</li>
						</ul>
						<div>
							<p>{{question[3].name}}</p>
						</div>
					</div>
					<div class="guidelines" style="margin-top: 28px;">
						<p v-for="(item,index) in pageInfo.industry[3]">{{item.description}}</p>
					</div>

					<div class="valueResultTitle" style="margin-top: 41px;">
						<img src="../../assets/img/report/icon10.png" class="icon_" alt="心流">
						<span>{{question[4].name}} &nbsp;{{question[4].value}}分</span>
					</div>
					<div class="indicator" v-if="pageInfo.industry" style="margin-top: 50px;margin-bottom: 0;">
						<img src="../../assets/img/report/circulationIndex.png">
						<ul class="circulationScore">
							<li style="left: 10px;">
								<p>{{pageInfo.industry[4][0].value}}分</p>
								<p>{{pageInfo.industry[4][0].name}}</p>
							</li>
							<li style="right: 10px;">
								<p>{{pageInfo.industry[4][1].value}}分</p>
								<p>{{pageInfo.industry[4][1].name}}</p>
							</li>
						</ul>
						<div>
							<p>{{question[4].name}}</p>
						</div>
					</div>
					<div class="guidelines" style="margin-top: 28px;">
						<p v-for="(item,index) in pageInfo.industry[4]">{{item.description}}</p>
					</div>
					<div class="pagination">06</div>
				</div>
			</div>
			<div class="page">
				<div class="content page-content" id="page-eight">
					<div class="valueResultTitle">
						<img src="../../assets/img/report/icon11.png" class="icon_" alt="心流">
						<span>{{question[5].name}} &nbsp;{{question[5].value}}分</span>
					</div>
					<div class="indicator" v-if="pageInfo.industry">
						<img src="../../assets/img/report/costIndex.png">
						<ul class="costIndexScore">
							<li style="left: 0px;">
								<p>{{pageInfo.industry[5][0].value}}分</p>
								<p>{{pageInfo.industry[5][0].name}}</p>
							</li>
							<li style="right: 40%;top: 307px;">
								<p>{{pageInfo.industry[5][1].value}}分</p>
								<p>{{pageInfo.industry[5][1].name}}</p>
							</li>
							<li style="right: 0px;">
								<p>{{pageInfo.industry[5][2].value}}分</p>
								<p>{{pageInfo.industry[5][2].name}}</p>
							</li>
						</ul>
						<div>
							<div>
								<img src="../../assets/img/report/indicatorCosts.png">
								<p>{{question[5].name}}</p>
							</div>
						</div>
					</div>
					<div class="guidelines" style="margin-top: 50px;">
						<p v-for="(item,index) in pageInfo.industry[5]">{{item.description}}</p>
					</div>
					<div class="pagination">07</div>
				</div>
			</div>
			<div class="page">
				<div class="content page-content" id="page-nine">
					<div class="valueResultTitle">
						<img src="../../assets/img/report/icon12.png" class="icon_" alt="心流">
						<span>MLM估值</span>
					</div>
					<p style="font-size: 20px;color: #282828;line-height: 42px;padding: 30px 0 0 74px;text-align: justify;">
						MLM（Machine Learning Method，机器学习法）是指通过PCA主成分析、对比学习、知识图谱、混合专家模型等多种机器学习/深度学习方法。
					</p>
					<div class="profitData" id="profitData">
						<svg width='480' height='403'>
							<text class="rotateYear" :x="profitDataYearX[index]" y="76" transform='rotate(-17 280 100)'
								v-for="(item, index) in boxhead">{{item}}</text>
							<text class="rotateData" :x="profitDataIncomeX[index]" y="140" transform='rotate(-17 280 100)'
								v-for="(item, index) in income" :key="index">{{index==0?item:method.formateNum(item, 2)}}</text>
							<text class="rotateDataBlue" :x="profitDataNetAssetX[index]" :y="profitDataNetAssetY[index]"
								transform='rotate(-16 280 100)' v-for="(item, index) in netAsset"
								:key="index+'1'">{{index==0?item:method.formateNum(item, 2)}}</text>
							<text class="rotateData" :x="profitDataNetProfitX[index]" y="224" transform='rotate(-16 280 100)'
								v-for="(item, index) in netProfit" :key="index+'2'">{{index==0?item:method.formateNum(item, 2)}}</text>

							<text class="rotateDataBlue" x="97" y="258" transform='rotate(-17 280 100)'
								style="font-weight: bold;">公司息税折旧</text>
							<text class="rotateDataBlue" x="97" y="276" transform='rotate(-17 280 100)'
								style="font-weight: bold;">摊销前利润</text>

							<text class="rotateDataBlue" x="288" y="270"
								transform='rotate(-17 280 100)'>{{method.formateNum(amortizeProfit[1], 2)}}</text>

						</svg>
					</div>
					<img src="../../assets/img/report/machineLearning.png" alt="MLM估值-心流"
						style="margin: -48px auto 0;display: block;">

					<div class="machineLearningValuationResults specialNumeric">
						<img src="../../assets/img/report/technologyBox.png" alt="机器学习估值结果-心流">
						<div class="numberBox">
							<span class="valuationMethod">MLM估值：</span>
							<img v-for="(item, index) in machineLearningValuationResults" :key="index + 1"
								:src="require('../../assets/img/report/'+item.val+'.png')"
								:style="{marginTop:(item.val=='11'?'52px':'0'),width:(item.val=='11'?'5px':'36px')}" alt="估值结果数字-心流">
							<p class="unit" style="font-size: 24px;top: 18px;">
								{{method.technologyNum(pageInfo.relativeValuationValue).unit}}
							</p>
						</div>
					</div>
					<ul class="interpretation">
						<li>
							1、模型基于上市和非上市公司数据，选取合适的估值、统计分析方法标注形成数据集，对估值模型训练。
						</li>
						<li>
							2、心流深度学习估值模型利用神经网络，通过采用梯度下降法最小化损失函数对网络中的权重参数逐层反向调节，通过对训练数据随时间的推移不断学习并提高准确性，利用计算科学智能地计算数据资产价值。
						</li>
					</ul>
					<div class="pagination">08</div>
				</div>
			</div>
			<div class="page">
				<div class="content page-content" id="page-ten">
					<div class="valueResultTitle">
						<img src="../../assets/img/report/icon13.png" class="icon_" alt="心流">
						<span>RSM估值</span>
					</div>
					<ul class="revenueContributionInterpretation">
						<li> RSM（Revenue Sharing
							Method，收入分成法）是指根据数据资产的历史应用情况及未来应用前景，结合应用数据资产的企业经营状况，估算数据资产的未来预期收益，采用适当的折现率折算成现值，借以确定数据资产价值的一种估值方法。</li>
					</ul>
					<div class="numberBox">
						<span class="valuationMethod">估值结果：</span>
						<img v-for="(item, index) in revenueContributionResults" :key="index + 1"
							:src="require('../../assets/img/report/'+item.val+'.png')"
							:style="{marginTop:(item.val=='11'?'60px':'0'),width:(item.val=='11'?'5px':'42px')}" alt="估值结果数字-心流">
						<p class="unit" style="font-size: 24px;top: 18px;">
							{{method.technologyNum(pageInfo.absoluteValuationValue).unit}}
						</p>
					</div>
					<div class="contribution1">
						<img src="../../assets/img/report/contribution1.png" />
					</div>
					<div class="contribution2">
						<ul>
							<li>
								<div></div>
								<p>收益年限</p>
								<img src="../../assets/img/report/contribution2.png" alt="心流">
							</li>
							<li style="left: -61px;">
								<div></div>
								<p>第i年产生的收益</p>
								<img src="../../assets/img/report/contribution2.png" alt="心流">
							</li>
							<li style="left: -27px;">
								<div></div>
								<p>收入分成率</p>
								<img src="../../assets/img/report/contribution2.png" alt="心流">
							</li>
							<li>
								<div></div>
								<p>折现率</p>
							</li>
						</ul>
						<img src="../../assets/img/report/contribution3.png" alt="心流"
							style="position: absolute;top: 25px;left: 256px;">
					</div>
					<div class="pagination">09</div>
				</div>
			</div>
			<div class="page">
				<div class="content page-content" id="page-eleven">
					<div class="valueResultTitle">
						<img src="../../assets/img/report/icon14.png" class="icon_" alt="心流">
						<span>收入预测</span>
					</div>
					<div style="position: relative;width: 945px;margin: 0 auto;">
						<img class="revenueForecastingBase" src="../../assets/img/report/revenueForecastingBase.png" alt="心流">
						<Histogram :id="'incomeStatement'" :chartData="incomeForecast" v-if="charts" />
					</div>
					<div class="valueResultTitle" style="margin-top: 69px;margin-bottom: 45px;">
						<img src="../../assets/img/report/icon16.png" class="icon_" alt="心流">
						<span>账面净值</span>
					</div>
					<table class="netBookValue" border="0" style="border-collapse: collapse;">
						<thead>
							<th>数据资源名称</th>
							<th>账面净值（万元）</th>
						</thead>
						<tbody>
							<tr v-for="(item,index) in netBookValue" :key="index">
								<td>{{item.name}}</td>
								<td>{{item.bookValue}}</td>
							</tr>
							<tr class="summation" v-if="portfolio.length<=10">
								<td>合计</td>
								<td>{{summation}}</td>
							</tr>
						</tbody>
					</table>

					<div class="pagination">10</div>
				</div>
			</div>
			<!-- netBookValuePaging -->
			<div class="page" v-if="pagesNumber.length>0" v-for="(pagination,i) in pagesNumber">
				<div class="content page-content" :id="'page-netBookValue'+i">
					<table class="netBookValue" border="0" style="border-collapse: collapse;">
						<thead>
							<th>数据资源名称</th>
							<th>账面净值（万元）</th>
						</thead>
						<tbody>
							<tr v-for="(item,index) in pagination" :key="index">
								<td>{{item.name}}</td>
								<td>{{item.bookValue}}</td>
							</tr>
							<tr class="summation" v-if="i==pagesNumber.length-1">
								<td>合计</td>
								<td>{{summation}}</td>
							</tr>
						</tbody>
					</table>
					<div class="pagination">{{i+11}}</div>
				</div>
			</div>
			<div class="page">
				<div class="content page-content" id="page-twelve">
					<div class="valueResultTitle">
						<img src="../../assets/img/report/icon15.png" class="icon_" alt="心流">
						<span>估值结果</span>
					</div>

					<div class="valuationResultDV">
						<div class="numberBox">
							<div>
								<div v-for="(item, index) in valueResultNumber" :key="index + 1" class="locationUnit">
									<p class="unit" v-if="item.val==12" style="margin-right: 12px;">
										{{method.technologyNum(pageInfo.minValue).unit}}
									</p>
									<img :src="require('../../assets/img/report/'+item.val+'.png')"
										:style="{marginTop:item.top,width:(item.val=='11'?'5px':item.val=='12'?'20px':'46px')}"
										alt="估值结果数字-心流">
								</div>
								<p class="unit">{{method.technologyNum(pageInfo.maxValue).unit}}</p>
							</div>

						</div>
					</div>
					<img src="../../assets/img/report/valuationResultDV.png">
					<div class="pagination">{{pagesNumber.length+11}}</div>
				</div>
			</div>

			<div class="page" v-if="channel">
				<div class="content page-content" id="page-stock">
					<img class="introduce-img" :src="pageInfo.introImage" alt="公司简介" />
					<div class="pagination">{{pagesNumber.length+12}}</div>
				</div>
			</div>

			<div class="page">
				<div class="content page-content" id="disclaimer">
					<div class="page-last">
						<img src="../../assets/img/report/map@2x.png" alt="线上估值-心流" />
						<div class="flow-statement">
							<img src="../../assets/img/report/flowData.png" alt="线上估值-心流" />
							<ul>
								<li>
									<div class="statement-tit">
										<span class="xian"></span>
										<p class="dian"><img src="../../assets/img/report/statement-01@2x.svg" alt="icon-心流"></p>
										<p>估值声明</p>
										<p class="dian dian_"><img src="../../assets/img/report/statement-01@2x.svg" alt="icon-心流"></p>
										<span class="xian"></span>
									</div>
									<div class="statement-main">
										本报告由系统自动生成。该系统依据企业提供的财务数据和软性指标，结合具备可靠性水平的数据库，根据通用的行业业绩及财务预测逻辑和规则，对企业估值所需要的必要参数进行预测和估算，企业提供资料的完整度和准确性对最终估值结果有重要影响。请本报告使用者知悉。
									</div>
								</li>
								<li>
									<div class="statement-tit">
										<span class="xian"></span>
										<p class="dian"><img src="../../assets/img/report/statement-01@2x.svg" alt="icon-心流"></p>
										<p>免责声明</p>
										<p class="dian dian_"><img src="../../assets/img/report/statement-01@2x.svg" alt="icon-心流"></p>
										<span class="xian"></span>
									</div>
									<div class="statement-main">
										系统所采用的数据信息来源于企业自行提供的资料和公开渠道可获取的行业信息和数据，并基于前述资料属实、合法的假设出具本报告，网站亦不对前述资料的准确性、完整性负有实质审核的义务，本报告仅作为对数据资产价值衡量的参考，非抵押、转让等交易的直接证据，网站及其运营方不承担因信赖本报告信息而采取行动所导致的任何损失及一切法律责任。
									</div>
								</li>
								<li>
									<div class="statement-tit">
										<span class="xian"></span>
										<p class="dian"><img src="../../assets/img/report/statement-01@2x.svg" alt="icon-心流"></p>
										<p>心流数据</p>
										<p class="dian dian_"><img src="../../assets/img/report/statement-01@2x.svg" alt="icon-心流"></p>
										<span class="xian"></span>
									</div>
									<div class="statement-main lastStatement-main">
										<p>
											北京心流数据科技有限公司（简称“心流数据”）是一家行业领先的数字投行 (DiBanking)，专注于数据变现生态建设，为客户提供“估值+组合式金融服务”。
											现为文化科技创新服务联盟金融专委会主任委员单位。
										</p>
										<div>
											<img src="../../assets/img/report/wechartCode.jpg" alt="">
											<ul>
												<li><span><i style="margin-right:36px;">网</i><i>址</i></span>：www.flcccc.com</li>
												<li><span>客服电话</span>：17610076778</li>
												<li><span><i style="margin-right:9px;">微</i><i
															style="margin-right:9px;margin-top:-1px;">信</i><i>号</i></span>：Flcccc888</li>
												<li><span><i style="margin-right:36px;">地</i><i>址</i></span>：北京市朝阳区酒仙桥路甲12号电子城科技大厦15层1505
												</li>
											</ul>
										</div>
									</div>
								</li>
							</ul>
						</div>
					</div>

					<div class="pagination">{{channel?pagesNumber.length+13:pagesNumber.length+12}}</div>
				</div>
			</div>



		</div>


		<div id="loading" style="display: none;">
			<div class='base'>
				<div class='cube'></div>
				<div class='cube'></div>
				<div class='cube'></div>
				<div class='cube'></div>
				<div class='cube'></div>
				<div class='cube'></div>
				<div class='cube'></div>
				<div class='cube'></div>
				<div class='cube'></div>
			</div>
		</div>
	</div>
</template>

<script>
	import qs from 'qs'
	import '../../assets/css/loading.css'
	import PdfOnload from '../../utils/pdf'
	import Draw3DPieCanvas from '../../common/echarts/draw3DPieCanvas.vue'
	import Histogram from '../../common/echarts/histogram.vue'

	export default ({
		components: {
			Draw3DPieCanvas,
			Histogram
		},
		data() {
			return {
				reportId: this.$route.query.reportId ? this.$route.query.reportId : '',
				channel: this.$route.query.channel ? this.$route.query.channel : '',
				pdfArr: [],
				pdfIndex: 0,
				pageInfo: {
					createTime: 'xxxx.xx.xx',
					baseInfo: {
						companyName: '',
						establishDate: '',
					},
					reportNumber: '',
					year: [],
					absoluteValuation: {
						ggm: ''
					},
					industry: '',
					firstPageImage: '', //合作公司的logo
					introImage: '' //合作公司的简介
				},
				valueResultNumber: [], //估值结果的数字
				question: [{
					name: '',
					value: ''
				}, {
					name: '',
					value: ''
				}, {
					name: '',
					value: ''
				}, {
					name: '',
					value: ''
				}, {
					name: '',
					value: ''
				}, {
					name: '',
					value: ''
				}],
				boxhead: ['项目', 'xxxx年预测'],
				income: ['公司营业收入', 0],
				netAsset: ['公司净资产', 0],
				netProfit: ['公司净利润', 0],
				amortizeProfit: ['公司息税折旧摊销前利润', 0],
				profitDataYearX: ['125', '260'],
				profitDataIncomeX: ['130', '305'],
				profitDataNetAssetX: ['120', '302'],
				profitDataNetAssetY: ['184', '182'],
				profitDataNetProfitX: ['110', '295'],
				machineLearningValuationResults: [], //机器学习估值法结果特殊数字(ML估值法)
				revenueContributionResults: [], //收入贡献法估值
				incomeForecast: {
					data: [],
					year: []
				},
				dataType: '', //数据类型
				portfolio: [],
				netBookValue: [], //账面净值
				summation: 0, //账面净值合计
				netBookValuePaging: [],
				pagesNumber: [],


			}
		},
		computed: {
			initStatus: function() {
				if (this.pageInfo.baseInfo.companyName) {
					return true;
				} else {
					return false;
				}
			},
			charts() {
				if (this.incomeForecast.data.length > 0) {
					return true;
				} else {
					return false;
				}
			}
		},
		mounted() {
			this.init()
		},
		methods: {
			init() {
				var that = this;
				this.$post(this.domain.valuationProject + 'valuationReport/getDataValuationReport', qs.stringify({
						reportId: this.reportId,
						channel:this.channel
					}))
					.then((res) => {
						var data = res.data;
						console.log(data)
						if (data.code == 200 && data.content) {
							that.pageInfo = data.content;

							// 数据类型
							if (data.content.baseInfo.dataResourceType) {
								JSON.parse(data.content.baseInfo.dataResourceType).map((el, i) => {
									if (i == JSON.parse(data.content.baseInfo.dataResourceType).length - 1) {
										this.dataType += el
									} else {
										this.dataType += el + '、'
									}
								})
							}


							// 设置估值结果页特殊数字
							var minArr = this.method.switchNum(that.pageInfo.minValue, that.pageInfo.maxValue)
								.min.split('');
							var maxArr = this.method.switchNum(that.pageInfo.minValue, that.pageInfo.maxValue)
								.max.split('');
							minArr.push('12')
							var newArr = minArr.concat(maxArr);
							this.specialNumeric(newArr, "valueResultNumber")

							// 股权结构
							if (that.pageInfo.baseInfo.holderRation) {
								var holderArr = [];
								var newHolderRation = JSON.parse(that.pageInfo.baseInfo.holderRation);
								var deep = [50, 70];
								for (var i = 0; i < newHolderRation.length; i++) {
									holderArr.push({
										name: newHolderRation[i].name,
										y: Number(newHolderRation[i].value),
										sliced: true,
										selected: true
									})
								}
								that.pageInfo.baseInfo.holderRation = holderArr;
							}


							// 软性指标
							this.question = JSON.parse(that.pageInfo.question)
							that.pageInfo.industry = JSON.parse(that.pageInfo.industry)

							this.income[1] = that.pageInfo.financialForecast.income // 营业收入
							this.netAsset[1] = that.pageInfo.financialForecast.netAsset //净资产预测
							this.netProfit[1] = that.pageInfo.financialForecast.netProfit //净利润预测
							this.amortizeProfit[1] = that.pageInfo.financialForecast.amortizeProfit //折旧摊销预测

							// 机器学习估值法结果特殊数字(ML估值法)
							this.boxhead[1] = that.pageInfo.financialForecast.year + '年预测'
							var machineLearning = this.method.technologyNum(that.pageInfo
								.relativeValuationValue).data.split('');
							if (that.pageInfo.relativeValuationValue < 0) {
								var Minus = 2;
							} else {
								var Minus = 1;
							}
							this.specialNumeric(machineLearning, "machineLearningValuationResults", Minus)

							// 收入贡献法估值
							var revenueContributionResults = this.method.technologyNum(that.pageInfo
								.absoluteValuationValue).data.split('');
							if (that.pageInfo.absoluteValuationValue < 0) {
								var Minus = 2;
							} else {
								var Minus = 1;
							}
							this.specialNumeric(revenueContributionResults, "revenueContributionResults", Minus)

							// 收入预测
							var incomeForecast = JSON.parse(that.pageInfo.financialIncome.income)
							// var incomeForecast=[{
							// 	value:51
							// },{
							// 	value:20,
							// }]
							this.incomeForecast.data = []
							this.incomeForecast.year = []
							incomeForecast.map((el, i) => {
								this.incomeForecast.data.push(el.value)
								this.incomeForecast.year.push(Number(that.pageInfo.financialForecast.year) + i + '年')
							})

							// 账面净值
							var arr = JSON.parse(that.pageInfo.financialBookValue.portfolio);
							// var arr = [];
							var pagination = []; //有几页 ,一整页可以放25个   24+合计
							var amount = 0
							// 35     60
							// for (var i = 0; i < 35; i++) {
							// 	arr.push({
							// 		bookValue: 1,
							// 		name: '数据名称' + Number(i + 1) + '------' + i
							// 	})
							// }
							console.log(arr)
							this.portfolio = arr

							arr.map(el => {
								this.summation += Number(el.bookValue)
							})

							if (arr.length > 10) {
								this.netBookValue = arr.slice(0, 11)
								this.pagesNumber = []
								amount = arr.slice(11, arr.length).length

								console.log(amount)
								if (arr.slice(11, arr.length).length < 25) {
									this.pagesNumber.push(arr.slice(11, arr.length))
								} else {
									if (amount % 25 === 0) {
										console.log('能整除')
										pagination = amount / 25
									} else {
										pagination = parseInt(amount / 25) + 1
										console.log('不能整除')
									}

									console.log('要分成几页-----', pagination)

									for (var i = 0; i < pagination; i++) {
										if (i == 0) {
											this.pagesNumber.push(arr.slice(11, (i + 1) * 25 + 11)) //i*24+10  是下标
										} else if (amount % 25 === 0) {
											this.pagesNumber.push(arr.slice(i * 25 + 11, (i + 1) * 25 + 11))
										} else {
											if (pagination.length < 3) {
												this.pagesNumber.push(arr.slice(i * 25 + 11, arr.length))
											} else {
												this.pagesNumber.push(arr.slice(i * 25 + 11, (i + 1) * 25 + 11))
											}
										}
									}
								}

							} else {
								this.netBookValue = arr
							}
						}

					})
			},
			// 特殊数字的转换
			specialNumeric: function(data, key, minus) { //minus==1 代表正数   minus==2代表负数
				if (minus == 2) {
					this[key].push({
						val: '12',
						top: "0px"
					})
				}
				data.forEach((val, i) => {
					if (val == ".") {
						this[key].push({
							val: "11",
							top: "62px",
						})
					} else {
						this[key].push({
							val: val,
							top: "0px"
						})
					}
				})
			},
			// 日期格式化
			dateFormate: function(date) {
				if (date) {
					var dataStr = date.split("-");
					var newDataStr = dataStr[0] + "年" + dataStr[1] + "月" + dataStr[2] + "日";
					return newDataStr;
				}
			},
			// 得分校对
			scoreFormate: function(score) {
				if (this.pageInfo.baseInfo.companyName) {
					for (var i = 0; i < this.pageInfo.question.length; i++) {
						if (this.pageInfo.question[i].name == score) {
							return this.pageInfo.question[i].value + '分';
						}
					};
				}
			},
			// 估值结果格式化
			resultFormate: function(min, max) {
				var val = '';
				if (min) {
					return this.method.valuationResultConversion(min, max)
				} else {
					return '无数据'
				}
			},
			valueFormate: function(val) {
				if (val) {
					return this.method.resultConversion(val)
				} else {
					return '无数据'
				}
			},
			/*页脚日期格式转换*/
			replaceAll: function(val) {
				if (val) {
					return val.replace(new RegExp("-", "gm"), ".");
				}

			},
			pdf: function() {
				document.getElementById("loading").style.display = "block";
				var title = this.pageInfo.baseInfo.companyName + '数据估值报告.pdf'
				var pageArr = document.getElementsByClassName("content");
				this.pdfArr = [];
				// this.pdfArr.push('homePage','page-one')
				for (var i = 0; i < pageArr.length; i++) {
					this.pdfArr.push(pageArr[i].id)
				}
				console.log(this.pdfArr)
				PdfOnload.getPdf(this.pdfArr, this.pdfIndex, title);
			}
		}


	})
</script>

<style scoped lang="less">
	.tools {
		width: 1160px;
		height: 60px;
		margin: 0 auto;
		padding-top: 10px;
		box-sizing: border-box;
	}

	.tools button {
		float: right;
		width: 120px;
		padding: 8px;
		border: 1px #aaa solid;
		border-radius: 4px;
		outline: none;
		font-size: 16px;
		transition: background-color 0.3s;
		background: #B1781E;
		color: #fff;
		margin-right: 20px;
		cursor: pointer;
	}

	.page {
		width: 100%;
		height: 1754px;
		position: relative;
		line-height: normal;
		vertical-align: middle;
	}

	.content {
		width: 1240px;
		height: 100%;
		background: #fff;
		margin: 0 auto;
		position: relative;
		overflow: hidden;
		border-top: 1px solid #ccc;
	}

	.page-content {
		padding-top: 114px;
		padding-left: 110px;
		padding-right: 110px;
	}

	.pagination {
		width: 52px;
		height: 54px;
		line-height: 54px;
		font-size: 20px;
		color: #fff;
		text-align: center;
		position: absolute;
		bottom: 37px;
		left: 50%;
		margin-left: -26px;
		background: url(../../assets/img/report/pageNumber.png) bottom center no-repeat;
		background-size: 100% 100%;
	}

	/* 封面 */

	#homePage {
		overflow: hidden;
		background: url(../../assets/img/report/pageIndex_bg.png)no-repeat center center;
	}

	.logoBox {
		overflow: hidden;
	}

	.homePage h1 {
		width: 293px;
		height: 99px;
		background: url(../../assets/img/report/flowData.png)no-repeat center center;
		background-size: 100%;
		margin: 81px 0 0 75px;
		float: left;
	}

	#logo {
		width: 293px;
		margin: 81px 0 0 75px;
	}

	.stock-logo {
		margin: 81px 0 0 75px;
		display: flex;
		align-items: center;
	}

	.stock-logo .logoXian {
		width: 2px;
		height: 99px;
		background: #7F7F7F;
		margin: 0 20px;
	}

	.stock-logo img {
		height: 80px;
	}
	
	#page-stock{
		padding: 0;
	}

	#page-stock>.introduce-img {
		width: 100%;
		height: 100%;
		display: block;
		position: absolute;
		top: 0;
	}

	.homePage h4 {
		font-size: 24px;
		font-weight: 400;
		line-height: 100%;
		color: #000;
		text-align: center;
		margin: 42px auto 0;
		text-align: center;
	}

	.homePage .content {
		border: none;
	}

	.companyName {
		font-size: 50px;
		font-weight: bold;
		color: #000;
		text-align: center;
		margin: 118px 0 40px 0;
	}

	.homePage>div>div.homePageLine {
		width: 796px;
		height: 6px;
		background: #1416F4;
		margin: 0 auto;
	}

	.homePage .reportType {
		font-size: 130px;
		font-weight: bold;
		color: #1416F4;
		margin: 60px 0 10px 0;
		text-align: center;
	}

	.homePage h3 {
		font-size: 34px;
		font-weight: bold;
		color: #388BFF;
		text-align: center;
		margin-bottom: 22px;
	}

	.page-one-footer {
		position: absolute;
		bottom: 50px;
		height: 21px;
		width: 1100px;
		margin: 0 34px;
	}

	.page-one-footer>p {
		float: right;
		margin-right: 193px;
		font-size: 18px;
		color: #282828;
		line-height: 1;
	}

	.page-one-footer>p>span:nth-child(2) {
		margin: 0 5px;
	}

	.page-one-footer>p>span:last-of-type {
		color: #0D0D0D;
	}

	.page-one-footer>div.date {
		float: right;
		font-size: 18px;
		line-height: 1;
		color: #0D0D0D;
	}

	/* 新目录 */

	.page-catalogue h2 {
		font-size: 48px;
		font-weight: bold;
		color: #0F060B;
		margin: 172px auto 69px;
		text-align: center;
		line-height: 1;
	}

	.page-catalogue .generalize>div {
		width: 980px;
		height: 54px;
		margin: 34px auto 0px;
		display: flex;
		align-items: center;
	}

	.page-catalogue .generalize>div p:first-child {
		font-size: 32px;
	}

	.page-catalogue .generalize p {
		font-size: 24px;
		font-weight: bold;
		color: #0F060B;
		margin-right: 11px;
		line-height: 54px;
	}

	.page-catalogue .generalize>div>div {
		width: 6px;
		height: 30px;
		background: #1416F4;
		float: left;
		margin: 0 28px 0 0;
	}

	.page-catalogue .generalize .dottedLine {
		float: left;
		height: 2px;
		width: 733px;
		margin: 0 20px 0;
		background: url(../../assets/img/report/catalogue_.png)left center repeat;
	}

	.page-catalogue .generalize div>.dottedLine {
		margin: 0 20px 0;
	}

	.page-catalogue .generalize .pageNumber {
		float: left;
		font-size: 18px;
		font-weight: 400;
		color: #282828;
		line-height: 100%;
	}

	.page-catalogue .generalize ul {
		margin: 0 0 57px 194px;
	}

	.page-catalogue .generalize ul li {
		height: 30px;
		margin: 9px 0 13px 0;
		width: calc(980px - 64px);
		display: flex;
		align-items: center;
	}

	.page-catalogue .generalize ul li p {
		font-size: 18px;
		font-weight: 400;
		color: #423838;
		line-height: 30px;
	}

	.page-catalogue .generalize ul li .dottedLine {
		width: 760px;
	}

	.page-catalogue .generalize ul.flowChart li .dottedLine {
		width: 797px;
	}

	// 基本信息
	.valueResultTitle {
		display: flex;
		align-items: center;
	}

	.icon_ {
		height: 52px;
		margin-right: 25px;
		vertical-align: middle;
	}

	.icon_+span {
		font-size: 30px;
		font-weight: bold;
		color: #282828;
		line-height: 52px;
	}

	#companyName {
		font-size: 24px;
		font-weight: bold;
		color: #000000;
		margin-bottom: 20px;
	}

	.baseInfoCont,
	.productCont,
	.groupCont,
	.mainBusinessCont,
	.situationCont {
		width: 100%;
		overflow: hidden;
		padding: 20px 0 67px;
		margin-left: 74px;
	}

	.situationCont ul li span,
	.baseInfoCont ul li span,
	.productCont ul li span,
	.groupCont ul li span {
		font-size: 20px;
		font-weight: 400;
		color: #010000;
		line-height: 38px;
	}

	.mainBusinessCont>p {
		font-size: 20px;
		color: #282828;
		line-height: 40px;
		text-align: justify;
	}

	.equityStructure {
		position: relative;
	}

	.piePedestal {
		width: 600px;
		position: absolute;
		top: 352px;
		left: 50%;
		margin-left: -289px;
	}

	// 估值结果
	.stamp {
		width: 880px;
		font-size: 20px;
		color: #282828;
		line-height: 42px;
		text-align: justify;
		margin: 50px auto 78px;
	}

	.detail_result {
		width: 955px;
		height: 415px;
		margin: 0 auto;
		background: url(../../assets/img/report/result.png) center center no-repeat;
		position: relative;
	}

	.detail_result .numberBox {
		height: 100%;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-49%, -50%);
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.locationUnit {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.numberBox img {
		margin-right: 4px;
	}

	.numberBox img:last-child {
		// margin-right: 0;
	}

	.numberBox p.unit {
		width: 56px;
		font-size: 28px;
		font-weight: bold;
		color: #1416F4;
		position: relative;
		top: 20px;
	}

	.numberBox p.unit+img {
		margin-right: 17px !important;
	}

	.detail_result+img {
		margin: 10px auto 0;
		display: block;
	}

	// 软性指标
	.softIndexScore {
		width: 1023px;
		height: 816px;
		background-size: contain;
		margin: 0 auto;
		position: relative;

		>img {
			position: absolute;
			left: 0;
			top: 0;
		}

		div {
			width: 378px;
			height: 378px;
			margin: 0 auto;
			transform: translateY(44%);
			display: flex;
			justify-content: center;
			align-items: center;
			flex-wrap: wrap;

			span {
				width: 100%;
				color: #1416F4;
				text-align: center;
				font-weight: bold;
			}

			span:first-child {
				font-size: 53px;
				margin: 18px 0 10px;
			}

			span:last-child {
				font-size: 28px;
			}
		}
	}

	.scoreDescription {
		width: 880px;
		font-size: 20px;
		color: #282828;
		line-height: 42px;
		text-align: justify;
		margin: 65px auto 116px;
	}

	.guidelines {
		p {
			width: 880px;
			font-size: 20px;
			color: #282828;
			line-height: 42px;
			margin: 0 auto;
			text-indent: 2em;
			text-align: justify;
		}
	}

	.indicator {
		display: inline-block;
		position: relative;
		left: 50%;
		transform: translateX(-50%);
		margin-top: 76px;
		margin-bottom: 70px;

		>ul {
			width: 100%;
			position: absolute;
		}

		>div {
			position: absolute;
		}
	}

	.basicIndexScore+div {
		width: 100%;
		top: 60px;
		left: 0;
		text-align: center;

		p {
			font-size: 30px;
			font-weight: bold;
			color: #1416F4;
			margin-top: 11px;
		}
	}

	.basicIndexScore {
		top: 0;

		li {
			width: 221px;
			height: 256px;
			background: url(../../assets/img/report/basicIndexScore.png) center center no-repeat;
			position: absolute;
			top: 115px;

			p {
				width: 100%;
				text-align: center;
			}

			p:first-child {
				font-size: 24px;
				font-weight: bold;
				color: #1416F4;
				margin-top: 59px;
			}

			p:last-child {
				font-size: 20px;
				color: #fff;
				position: absolute;
				bottom: 26.5%;
			}
		}
	}

	.riskIndicatorScore+div {
		top: -38px;
	}

	.riskIndicatorScore {
		li {
			width: 262px;
			height: 155px;
			background: url(../../assets/img/report/riskIndicatorScore.png) center center no-repeat;
			top: 30px;

			p {
				color: #000 !important;
			}

			p:first-child {
				margin-top: -27px;
			}

			p:last-child {
				bottom: 77%;
			}
		}
	}

	.qualityIndexScore+div {
		width: 100%;
		text-align: center;
		position: absolute;
		top: 49px;

		p {
			font-size: 30px;
			font-weight: bold;
			color: #1416F4;
		}
	}

	.qualityIndexScore {
		top: -103px;
		left: -138px;

		li {
			position: absolute;

			>div {
				width: 158px;
				height: 86px;
				background: url(../../assets/img/report/riskIndicatorGrade.png) center center no-repeat;
				margin: 0 auto 6px;
				display: flex;
				align-items: center;
				justify-content: center;
				flex-wrap: wrap;

				p {
					text-align: center;
					font-size: 18px;
					margin-top: 5px;
					color: #000;
				}

				p:last-child {
					font-size: 20px;
					font-weight: bold;
				}
			}
		}
	}

	.economicScore+div {
		width: 100%;
		text-align: center;
		position: absolute;
		top: -45px;

		p {
			font-size: 30px;
			font-weight: bold;
			color: #1416F4;
		}
	}

	.economicScore {
		top: -22px;

		li {
			width: 250px;
			height: 70px;
			background: url(../../assets/img/report/economicIcon.png) center center no-repeat;
			position: absolute;
			text-align: center;

			span {
				line-height: 70px;
				color: #1416F4;
			}

			span:first-child {
				font-size: 20px;
			}

			span:last-child {
				font-size: 24px;
				font-weight: bold;
				margin-left: 10px;
			}
		}
	}

	.circulationScore+div {
		width: 100%;
		text-align: center;
		position: absolute;
		top: 7px;

		p {
			font-size: 30px;
			font-weight: bold;
			color: #1416F4;
		}
	}

	.circulationScore {
		top: 28px;

		li {
			width: 228px;
			height: 254px;
			background: url(../../assets/img/report/circulationIcon.png) center center no-repeat;
			position: absolute;
			text-align: center;

			p:first-child {
				font-size: 24px;
				color: #1416F4;
				margin-top: 71px;
				font-weight: bold;
			}

			p:last-child {
				width: 100%;
				font-size: 20px;
				color: #fff;
				position: absolute;
				bottom: 19%;
			}
		}
	}

	.costIndexScore+div {
		width: 288px;
		height: 288px;
		text-align: center;
		position: absolute;
		top: 18px;
		left: 296px;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-wrap: wrap;

		p {
			width: 100%;
			font-size: 30px;
			font-weight: bold;
			color: #fff;
			margin-top: 27px;
		}
	}

	.costIndexScore {
		top: 189px;

		li {
			width: 160px;
			height: 160px;
			background: url(../../assets/img/report/costIndexIcon.png) center center no-repeat;
			position: absolute;
			text-align: center;

			p:first-child {
				font-size: 24px;
				font-weight: bold;
				color: #fff;
				margin-top: 56px;
			}

			p:last-child {
				font-size: 20px;
				color: #fff;
				margin-top: 8px;
			}
		}
	}

	.interpretation {
		width: 850px;
		margin: -15px 0 0 96px;

		li {
			font-size: 16px;
			color: #282828;
			line-height: 34px;
			text-indent: -1.6em;
			text-align: justify;
		}
	}

	.profitData {
		width: 511px;
		height: 402px;
		background: url(../../assets/img/report/profit.png)center center repeat;
		background-size: 100% 100%;
		margin: 0 auto;
		position: relative;
		top: -26px;
	}

	.profitData svg:nth-of-type(1) {
		position: relative;
		// top: -14px;
	}

	.arrows {
		width: 60px;
		height: 75px;
		margin: 0 auto;
		display: block;
		position: relative;
		top: -17px;
	}

	.arrows+img {
		height: 388px;
		margin: 0 auto;
		display: block;
		position: relative;
		top: -40px;
	}

	.rotateYear {
		font-size: 18px;
		fill: #fff;
		font-weight: bold;
	}

	.rotateData {
		width: 50px;
		font-size: 14px;
		fill: #fff;
		text-anchor: middle;
	}

	.rotateDataBlue {
		width: 50px;
		font-size: 14px;
		fill: #1416F4;
		text-anchor: middle;
	}

	.rotateData:nth-of-type(3),
	.rotateDataBlue:nth-of-type(5),
	.rotateData:nth-of-type(7) {
		font-weight: bold;
	}

	.specialNumeric>img {
		display: block;
		margin: 0 auto;
	}

	.machineLearningValuationResults {
		width: 880px;
		height: 443px;
		padding-top: 30px;
		position: relative;
		top: -26px;
		background: url(../../assets/img/report/machineLearningValuationResults.png) center center repeat;
		background-size: 100% 100%;
		margin: 37px auto 0;
	}

	.machineLearningValuationResults .numberBox {
		width: 636px;
		height: 135px;
		position: absolute;
		top: 30px;
		left: 50%;
		transform: translateX(-50%);
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.valuationMethod {
		font-size: 22px;
		font-weight: bold;
		color: #000;
	}

	.revenueContributionInterpretation {
		margin-top: 60px;
		margin-left: 74px;

		li {
			font-size: 20px;
			color: #282828;
			line-height: 42px;
			text-align: justify;
		}
	}

	.revenueContributionInterpretation+.numberBox {
		width: 520px;
		height: 119px;
		background: url(../../assets/img/report/absoluteValuationResult.png) center center no-repeat;
		margin: 75px auto 0;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.contribution1 {
		width: 242px;
		height: 196px;
		margin: 45px auto;
		position: relative;
	}

	.contribution1::before {
		content: '';
		display: block;
		width: 3px;
		height: 47px;
		background: #1416F4;
		position: absolute;
		left: 50%;
		top: -46px;
		transform: translateX(-50%);
	}

	.contribution1::after {
		content: '';
		display: block;
		width: 3px;
		height: 47px;
		background: #1416F4;
		position: absolute;
		left: 50%;
		bottom: -45px;
		transform: translateX(-50%);
	}

	.contribution2 {
		width: 693px;
		height: 82px;
		border-top: 3px solid #1416F4;
		margin: 0 auto;
		position: relative;

		ul {
			display: flex;
			position: relative;

			li {
				position: relative;

				>div {
					width: 196px;
					height: 201px;
					background: url(../../assets/img/report/contributionIcon.png) center center no-repeat;
					margin-top: 82px;
					margin-bottom: 45px;
					position: relative;
				}

				p {
					width: 180px;
					height: 66px;
					line-height: 66px;
					background: #EEEEFF;
					border: 2px solid #1416F4;
					font-size: 20px;
					color: #282828;
					text-align: center;
				}

				>img {
					width: 29px;
					transform: rotate(-90deg);
					position: absolute;
					right: -24px;
					bottom: 7px;
				}

				>div::before {
					content: '';
					display: block;
					width: 47px;
					height: 85px;
					background: url(../../assets/img/report/contribution2.png) center center no-repeat;
					background-size: 100% 100%;
					position: absolute;
					left: 50%;
					top: -85px;
					transform: translateX(-50%);
				}

				>div::after {
					content: '';
					display: block;
					width: 3px;
					height: 47px;
					background: #1416F4;
					position: absolute;
					left: 50%;
					bottom: -45px;
					transform: translateX(-50%);
				}

			}

			>li:first-child {
				left: -96px;
			}

			>li:last-child {
				right: -8px;
			}
		}
	}

	#incomeStatement {
		width: 100%;
		height: 550px;
		margin: 50px auto 0;
	}

	.revenueForecastingBase {
		position: absolute;
		bottom: 0;
	}

	.valuationResultDV {
		width: 890px;
		height: 294px;
		background: url(../../assets/img/report/valuationResultDVBox.png) center center no-repeat;
		margin: 111px auto 0;

		.numberBox {
			width: 817px;
			height: 151px;
			margin: 0 auto;
			display: flex;
			justify-content: center;
			align-items: center;
			flex-wrap: wrap;
			position: relative;
			top: 106px;

			>div {
				width: 100%;
				display: flex;
				align-items: center;
				justify-content: center;

				img {
					margin-right: 5px;
				}

				span {
					font-size: 28px;
					font-weight: bold;
					color: #1416F4;
					margin-top: 40px;
				}
			}

			>p {
				width: 100%;
				font-size: 32px;
				font-weight: bold;
				color: #000000;
				text-align: center;
			}
		}
	}

	.valuationResultDV+img {
		margin-top: -20px;
	}


	.netBookValue {
		width: 950px;
		height: 608px;
		margin: 0 auto;
		position: relative;

		thead {
			th {
				width: 474px;
				height: 71px;
				line-height: 71px;
				text-align: center;
				font-size: 24px;
				font-weight: bold;
				color: #fff;
			}

			>th:first-child {
				background: url(../../assets/img/report/dataAssetName.png) center center no-repeat;
				margin-right: 3px;
			}

			>th:last-child {
				background: url(../../assets/img/report/bookValue.png) center center no-repeat;
			}

		}

		tbody {
			border: 2px solid #2426F4;
			position: absolute;
			top: 79px;

			tr {
				td {
					width: 474px;
					height: 54px;
					line-height: 54px;
					text-align: center;
					font-size: 20px;
					color: #000000;
					border-bottom: 2px solid #2426F4;
				}

				>td:first-child {
					padding-right: 3px;
				}
			}

			>tr:last-child {
				td {
					border: none;
				}
			}

			tr.summation {
				td {
					font-weight: bold;
				}
			}

		}
	}


	// 免责声明
	.page-last>img {
		width: 900px;
		height: 376px;
		margin: 0px auto 50px;
		display: block;
	}

	.flow-statement {
		width: 1008px;
		margin: 0 auto;
	}

	.flow-statement>img {
		display: block;
		width: 351px;
		margin: 0 auto;
	}

	.flow-statement>ul>li>div.statement-tit {
		height: 20px;
		width: 428px;
		margin: 50px auto 30px;
		font-size: 0;
		position: relative;
	}

	.statement-tit>span.xian {
		/*width: 100%;*/
		width: 135px;
		height: 1px;
		display: block;
		/*float: left;*/
		position: absolute;
		top: -1px;
		margin-top: 10px;
		background: #1416F4;
	}

	.statement-tit>span.xian:first-of-type {
		left: 2px;
	}

	.statement-tit>span.xian:last-of-type {
		right: 0;
	}

	.statement-tit>img {
		width: 12px;
		height: 12px;
		float: left;
		margin-top: 3px;
	}

	.statement-tit>img:first-of-type {
		margin-left: 135px;
	}

	.statement-tit>p {
		width: 136px;
		height: 20px;
		line-height: 20px;
		font-size: 22px;
		color: #282828;
		float: left;
		background: #FFF;
		text-align: center;
		font-weight: bold;
	}

	.statement-tit>p.dian {
		width: 11px;
		height: 11px;
		float: left;
		margin: -2px 0 0 136px;
	}

	.statement-tit>p.dian img {
		width: 100%;
		position: relative;
		top: -2px;
	}

	.statement-tit>p.dian_ {
		margin: -2px 0 0 0;
	}

	.statement-main {
		width: 977px;
		padding: 10px 20px;
		background: #E3EBFF;
		font-size: 18px;
		color: #282828;
		line-height: 32px;
		text-indent: 2em;
		text-align: justify;
		border-radius: 10px;
	}

	.statement-main div {
		margin: 24px 0 0 118px;
		overflow: hidden;
	}

	.statement-main div img {
		float: left;
		width: 138px;
		height: 138px;
		margin: 0 0 20px 0;
	}

	.statement-main div ul {
		margin: 4px 0 0 0;
	}

	.statement-main div ul li {
		font-size: 18px;
		font-weight: 400;
		color: #282828;
		line-height: 30px;
	}

	.statement-main div ul li span {
		width: 72px;
		text-indent: 0;
		// text-align-last: justify;
		// text-align: justify;
		// text-justify: distribute-all-lines;
	}
</style>