import Vue from 'vue'
import Vuex from 'vuex'
import { storeModules } from 'shared'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
		channel: '',
		reportId:'',
		currentPageModify:false,// 当前页面是否已经修改，默认未修改
		currentPageSave:false,   // 当前页面是否已经保存，默认未保存
		checkStatus:{}
		
  },
  mutations: {
		changeChannel(state,data) {
			state.channel=data;
		},
		pageModify(state,data){
			state.currentPageModify=data;
		},
		setCheckStatus(state,data){
			state.checkStatus=data;
		}

		
  },
  actions: {
  },
  modules: {
		user:{
			...storeModules.myModule
		}
  }
})
