<template>
	<div class="EBG">
		<Header :type="type" />
		<div class="mainE"
			:style="{'marginTop':method.ratioEquality(1854,90,width-100)+30+'px','minHeight':height-method.ratioEquality(1854,90,width-100)-30+'px'}">
			<fillinNav :stage="stage" :type="type" :versions="versions" :currentColumn='3' />

			<div class="fillinWidth">
				<div class="fillinTitleE">
					<p>
						<img src="../../../assets/img/dataValutaion/dataTitle.png" />
						<span>无形资产-数据资源</span>
					</p>
				</div>

				<div class="fillinMain">
					<img class="leftTop" src="../../../assets/img/dataValutaion/leftTop.png" />
					<img class="leftBottom" src="../../../assets/img/dataValutaion/leftBottom.png" />
					<img class="rightTop" src="../../../assets/img/dataValutaion/rightTop.png" />
					<img class="rightBottom" src="../../../assets/img/dataValutaion/rightBottom.png" />
					<div class="substance">
						<div class="fillinContent">

							<DataResourceSpecialtyNav :stage="stage" :type="type" :versions="versions" :currentFinance='1' />

							<div class="newCategory">营业收入预测</div>

							<SinglenessForecast v-if="resourceType==0" ref="element" :stage="stage" :type="type" :versions="versions"
								:url="url" :dataInfo="dataInfo" :tableType="0" :saveMethod="url+'income/addIncome'"
								jumpAddress="dataNetProfitForecast" />

							<MultipleForecast v-if="resourceType==1" ref="element" :stage="stage" :type="type" :versions="versions"
								:url="url" :dataInfo="dataInfo" :tableType="0" :saveMethod="url+'income/addIncome'"
								jumpAddress="dataNetProfitForecast" />


							<div class="saveBox">
								<router-link v-if="this.$route.query.reportId" tag="span" class="last"
									:to="{ path: '/dataAssetSpecialty', query: { reportId:this.$route.query.reportId} }">上一步</router-link>
								<router-link v-else tag="span" class="last"
									:to="{ path: '/dataAssetSpecialty' }">上一步</router-link>
								<span data-isClick="false" @click="save">下一步</span>
							</div>

						</div>
					</div>
				</div>
			</div>
		</div>


	</div>
</template>

<script>
	import Header from '@/common/header/evaluate_header.vue'
	import fillinNav from '../nav/fillDataNav.vue'
	import DataResourceSpecialtyNav from '../nav/dataResourceSpecialtyNav.vue'
	import SinglenessForecast from '@/components/financialStatement/forecast/singleness.vue'
	import MultipleForecast from '@/components/financialStatement/forecast/multiple.vue'
	import common from '@/views/common'
	import qs from 'qs'
	import axios from 'axios';

	export default {
		mixins: [common],
		components: {
			Header,
			fillinNav,
			DataResourceSpecialtyNav,
			SinglenessForecast,
			MultipleForecast

		},
		data() {
			return {
				width: window.innerWidth,
				height: window.innerHeight,
				url: this.domain.testUrl + this.domain.valuationProject,
				stage: '',
				type: '13',
				versions: '2',
				dataInfo: {
					years: ['', '', ''],
					income: {
						products: [{
							name: '',
							prediction: [{
								value: ''
							}, {
								value: ''
							}, {
								value: ''
							}]
						}],
						productSum: {
							name: '',
							prediction: [{
								value: ''
							}, {
								value: ''
							}, {
								value: ''
							}]
						}
					}
				},
				resourceType: 1,

			}
		},
		mounted() {
			this.init()
		},
		methods: {
			init() {
				// 单一类型：当年（营业收入/净利润预测）取基本信息中（营业收入/净利润预测）的值，基本信息中（营业收入/净利润预测）预测的值为""时，只做大于0的验证
				// 多种：当年（营业收入/净利润预测）的总和要小于等于基本信息中（营业收入/净利润预测）的值，当年每条业务线的营业收入必须大于0

				this.$post(this.url + 'income/getIncomeByReportId', qs.stringify({
						stage: '',
						versions: '',
						reportId: this.$route.query.reportId ? this.$route.query.reportId : '',
						reportType: this.type
					}))
					.then(res => {
						var arry = res.data.content.years.length == 3 ? [0, 0, 0] : [0, 0]
						this.dataInfo.years = res.data.content.years

						if (res.data.content.income) {
							let data = JSON.parse(res.data.content.income)
							this.dataInfo = res.data.content
							this.dataInfo.income = data

							if (data.productSum) this.dataInfo.income.productSum = JSON.parse(data.productSum)

							if (data.products) {
								this.dataInfo.income.products = JSON.parse(data.products)
								// for (const product of this.dataInfo.income.products) {
								// 	for (let i = 0; i < product.prediction.length; i++) {
								// 		arry[i] += product.prediction[i].value != '-' ? Number(product.prediction[i].value) : 0;
								// 		this.dataInfo.income.productSum.prediction[i].value = arry[i]
								// 	}
								// }
							}

						}
						
						console.log('mmm----',this.dataInfo)


						if (res.data.content.resourceType == null) { //数据资源没填写时提示
							this.showDialogBar(true, 'notClose', '请填写数据资源')
							return false
						} else {
							this.resourceType = res.data.content.resourceType

							if (res.data.content.resourceType == 0 && res.data.content.baseInfoIncome != '') this.dataInfo.income
								.productSum.prediction[0].value = res.data.content.baseInfoIncome * 10000

						}



					})

			},
			save() {
				this.$refs.element.save()
			},
			//是  载入缓存
			clickCacheTrueMaskJump() {
				this.hideDialogBar()
			},

			//否  载入缓存
			clickCacheMaskJump() {
				this.hideDialogBar()
			},
			closeMaskInfoJump() {
				if (this.$route.query.reportId) {
					this.$router.push({
						name: 'dataAssetSpecialty',
						query: {
							reportId: this.$route.query.reportId
						}
					})
				} else {
					this.$router.push({
						name: 'dataAssetSpecialty',
					})
				}


				this.hideDialogBar()
			}


		}



	}
</script>

<style scoped lang="less">
	@import "~@/assets/css/dataValuationNew.less";
</style>