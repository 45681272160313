<template>
	<div>
		<div class="newCategory">利润表</div>
		<div class="financeTabulation">
			<p class="unit">单位：元</p>
			<div>
				<ul>
					<i></i>
					<li>
						<span>项目</span>
						<span v-for="(item,i) in yearList">{{item}}</span>
					</li>
					<li>
						<span>营业收入</span>
						<span v-for="(item,i) in yearList">
							<input type="number" v-model="profit.income[i]" @change="change"
								@blur="((ev)=>{blur(ev,profit,'income',i)})" @focus="focus($event)"
								@wheel="method.disableWheel($event)">
							<span class="showInput" @click="inputFocus($event)">
								{{judgingCondition('income',i)?method.formateNum(profit.income[i],2):'-'}}
							</span>
						</span>
					</li>
					<li>
						<span>营业成本</span>
						<span v-for="(item,i) in yearList">
							<input type="number" v-model="profit.cost[i]" @change="change" @blur="((ev)=>{blur(ev,profit,'cost',i)})"
								@focus="focus($event)" @wheel="method.disableWheel($event)">
							<span class="showInput" @click="inputFocus($event)">
								{{judgingCondition('cost',i)?method.formateNum(profit.cost[i],2):'-'}}
							</span>
						</span>
					</li>
					<li>
						<span>利息费用</span>
						<span v-for="(item,i) in yearList">
							<input type="number" v-model="profit.interestCost[i]" @change="change"
								@blur="((ev)=>{blur(ev,profit,'interestCost',i)})" @focus="focus($event)"
								@wheel="method.disableWheel($event)">
							<span class="showInput" @click="inputFocus($event)">
								{{judgingCondition('interestCost',i)?method.formateNum(profit.interestCost[i],2):'-'}}
							</span>
						</span>
					</li>
					<li>
						<span>利润总额</span>
						<span v-for="(item,i) in yearList">
							<input type="number" v-model="profit.totalProfit[i]" @change="change"
								@blur="((ev)=>{blur(ev,profit,'totalProfit',i)})" @focus="focus($event)"
								@wheel="method.disableWheel($event)">
							<span class="showInput" @click="inputFocus($event)">
								{{judgingCondition('totalProfit',i)?method.formateNum(profit.totalProfit[i],2):'-'}}
							</span>
						</span>
					</li>
					<li>
						<span>毛利润</span>
						<span v-for="(item,i) in yearList">
							<input type="number" v-model="profit.grossProfit[i]" disabled>
							<span class="showInput">
								{{judgingCondition('grossProfit',i)?method.formateNum(profit.grossProfit[i],2):'-'}}
							</span>
						</span>
					</li>
					<li>
						<span>所得税</span>
						<span v-for="(item,i) in yearList">
							<input type="number" v-model="profit.incomeTax[i]" @change="change"
								@blur="((ev)=>{blur(ev,profit,'incomeTax',i)})" @focus="focus($event)"
								@wheel="method.disableWheel($event)">
							<span class="showInput" @click="inputFocus($event)">
								{{judgingCondition('incomeTax',i)?method.formateNum(profit.incomeTax[i],2):'-'}}
							</span>
						</span>
					</li>
					<li>
						<span>净利润</span>
						<span v-for="(item,i) in yearList">
							<input type="number" v-model="profit.netProfit[i]" disabled>
							<span class="showInput">
								{{judgingCondition('netProfit',i)?method.formateNum(profit.netProfit[i],2):'-'}}
							</span>
						</span>
					</li>

					<i></i>
				</ul>
			</div>

		</div>

	</div>
</template>

<script>
	import common from '@/views/common'
	import qs from 'qs'
	import axios from 'axios';

	import {
		mapState,
		mapMutations
	} from 'vuex'

	export default {
		mixins: [common],
		props: {
			stage: {
				type: [String, Number]
			},
			type: {
				type: [String, Number]
			},
			versions: {
				type: [String, Number],
				required: false
			},
			url: String,
			jumpAddress: String //保存之后的跳转地址

		},
		data() {
			return {
				yearList: ['xxxx年度', 'xxxx年度', 'xxxx年xx月xx日'],
				id: '',
				isCacheValue: '',
				profit: {
					income: [],
					cost: [],
					interestCost: [],
					totalProfit: [],
					grossProfit: [],
					incomeTax: [],
					netProfit: []
				},
				isSave: false

			}
		},
		mounted() {
			this.getProfit()
			this.pageModify('false');
		},
		methods: {
			...mapMutations(['pageModify']),
			change() {
				this.pageModify('true');
				if (!this.$route.query.reportId || (this.$route.query.reportId && this.isCacheValue == '0') || (this.$route
						.query.reportId && !this.id)) {
					this.method.caching("profitInfo", JSON.stringify(this.profit), this.type, this.stage, this)
				}
			},
			inputFocus: function(e) {
				this.isSave = false
				e.currentTarget.style.display = 'none';
				e.currentTarget.previousSibling.style.display = 'block';
				e.currentTarget.previousSibling.focus();
			},
			focus(e) {
				e.currentTarget.setAttribute("placeholder", "")

			},
			judgingCondition: function(key, i, index) {
				if (this.profit[key][i] === 0) {
					return true
				} else if (this.profit[key][i] != 0 && this.profit[key][i] != '-') {
					return true
				} else {
					return false
				}
			},
			blur: function(e, data, name, num) { //data：资产负债表的字段   name：具体的科目名称   num：科目的具体下标
				this.isSave = false
				e.currentTarget.nextSibling.style.display = 'block';
				e.currentTarget.style.borderColor = '#D5D5D5';
				var y = String(data[name][num]).indexOf("."); //获取小数点的位置
				var count = String(data[name][num]).length - y; //获取小数点后的个数
				this.pageModify('true');

				if (data[name][num] == '') {
					this.$set(this.profit[name], num, '0')
				}

				if (data[name][num]) {
					if (String(data[name][num]).indexOf(".") != -1) {
						this.$set(this.profit[name], num, Math.round(Number(data[name][num]) * 100) / 100) //保留两位
					} else {
						if (data[name][num].length > 20) { //判断数据的合理性
							this.$Message.error({
								background: true,
								content: '请输入合理数据'
							});
							this.$set(this.profit[name], num, '')
						} else {
							this.$set(this.profit[name], num, Number(data[name][num]))
						}
					}
				} else {
					this.$set(this.profit[name], num, '')
				}

				for (var i = 0; i < this.yearList.length; i++) {
					// 毛利润=营业收入-营业成本
					this.profit.grossProfit[i] = this.method.numRound(this.profit.income[i]) - this.method.numRound(this.profit
						.cost[i])

					// 净利润=利润总额-所得税
					this.profit.netProfit[i] = this.method.numRound(this.profit.totalProfit[i]) - this.method.numRound(this
						.profit.incomeTax[i])

				}

				//调用缓存共用方法
				if (!this.$route.query.reportId || (this.$route.query.reportId && this.isCacheValue == '0') || (this.$route
						.query.reportId && !this.id)) {
					this.method.caching("profitInfo", JSON.stringify(this.profit), this.type, this.stage, this)
				}


			},
			getProfit: function(close) {
				this.$Spin.show(); //全局加载中
				var this_ = this;

				this.$post(this.url + 'financeProfitInfo/getProfitInfo', qs.stringify({
						reportId: this.$route.query.reportId ? this.$route.query.reportId : '',
						reportType: this.type,
						versions: this.versions,
						stage: this.stage,
					}))
					.then((res) => {
						this.$Spin.hide();
						var profitKey = Object.keys(this.profit); //获取利润表对象的 key

						this.yearList = res.data.content.year;


						this.id = res.data.content.id;
						this.isCacheValue = res.data.content.isCache

						if (res.data.content.profit) {
							this.profit = JSON.parse(res.data.content.profit)

							if (res.data.content.isCache == "0" && !sessionStorage.isHistoryCache) {
								this.showDialogBar(true, 'cacheType', '您上次填写过估值报告，是否载入缓存信息？')

								// 清除 0.00
								var dataKey = Object.keys(this.profit); //获取对象的 key
								dataKey.forEach((key, idx) => {
									this.yearList.forEach((item, i) => {
										this.profit[key].push("")
									})
								})

							} else { //正式数据
								var returnProfit = Object.keys(JSON.parse(res.data.content
									.profit)) //后台数据返回的利润表对象的 key
								profitKey.forEach((elem, index) => {
									returnProfit.forEach((item, i) => {
										this_.yearList.forEach((yearItem, y) => {
											if (elem == item) {
												this_.profit[elem][y] = JSON.parse(res.data.content.profit)[elem][y] ? JSON
													.parse(res.data.content.profit)[elem][y] : '';
											} else if (!this_.profit[elem]) {
												if (this_.yearList.length == 2) {
													this_.profit[elem] = ['', '']
												} else if (this_.yearList.length == 3) {
													this_.profit[elem] = ['', '', '']
												}
											}
										})
									})
								})
							}
						} else {
							var dataKey = Object.keys(this.profit); //获取对象的 key
							dataKey.forEach((key, idx) => {
								this.yearList.forEach((item, i) => {
									this.profit[key].push("")
								})
							})
						}

					});

			},
			save() {
				var this_ = this
				
				console.log(this.profit)

				if (!this.profit.income[this.profit.income.length - 1] && !this.profit.cost[this.profit.cost.length - 1] && !this
					.profit.interestCost[this.profit.interestCost.length - 1] && !this.profit.totalProfit[this.profit.totalProfit
						.length - 1] && !this.profit.incomeTax[this.profit.incomeTax.length - 1]) {
					this.showDialogBar(true, 'notEmpty', this.yearList[this.yearList.length - 1] + '利润表不能全部为零')
					return false;
				}

				this.profit.year = this.yearList

				if (!this.isSave) {
					this.isSave = true
					this.$post(this.url + 'financeProfitInfo/save', qs.stringify({
							stage: this.stage,
							reportType: this.type,
							id: this.id ? this.id : '',
							reportId: this.$route.query.reportId ? this.$route.query.reportId : '',
							profitInfo: JSON.stringify(this.profit),
							lastProfit: this.profit.netProfit[1] //最后一年的净利润
						}))
						.then((res) => {
							if (res.data.code == 200) {
								setTimeout(function() {
									this_.$router.push({
										name: this_.jumpAddress,
										query: {
											reportId: res.data.content.valuationReportId
										}
									})
								}, 500)

							}
						})
				}

			},
			//是  载入缓存
			clickCacheTrueMaskJump() {
				sessionStorage.isHistoryCache = 1;
				this.getProfit();
				this.hideDialogBar()
			},

			//否  载入缓存
			clickCacheMaskJump() {
				sessionStorage.isHistoryCache = 1;
				this.method.clearCaching(this.type, this.stage, this, 'getProfit')
				this.hideDialogBar()
			},
			closeMaskInfoJump() {
				this.hideDialogBar()
			}




		}


	}
</script>

<style>
</style>